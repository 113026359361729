<template>
    <section id="candidates-swiper">
        <div class="container">
            <swiper :modules="[Pagination, Autoplay]" :loop="true" :centered-slides="true"
                :pagination="{ clickable: true }" :autoplay="{ delay: 3000, disableOnInteraction: false }" :breakpoints="{
                    640: {
                        slidesPerView: 1.25,
                        spaceBetween: 10,
                    },
                    768: {
                        slidesPerView: 1.5,
                        spaceBetween: 20,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },
                }">
                <swiper-slide v-for="(candidate, index) in candidates" :key="index">
                    <div class="card">
                        <img :src="candidate.profilePic ? `${apiConfig.baseUrl}${candidate.profilePic}` : '/female-place-holder.jpg'"
                            alt="Candidate" />
                        <div class="card-body">
                            <h4 class="text-danger fst-italic fw-bold">{{ candidate.userPK }}</h4>
                            <h5 class="text-warning">{{ candidate.name }}</h5>
                            <p>{{ candidate.agency }}</p>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
    </section>
</template>

<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Autoplay } from "swiper/modules";
import { ref, onMounted } from "vue";
import dashboardService from "@/services/dashboardService";
import { apiConfig } from "@/utils/config";

const candidates = ref([{  }
]);
// Fetch candidates data
const getCandidates = async () => {
    const resp = await dashboardService.getCandidates();

    candidates.value = resp;
};

// Fetch data when the component is mounted
onMounted(() => {
    getCandidates();
});
</script>

<style scoped>
/* General Container */
#candidates-swiper {
    padding: 20px;
}

/* Swiper Slide Styling */
.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

/* Card Styling */
.card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: center;
    width: 100%;
    max-width: 300px;
    transition: transform 0.3s ease-in-out;
}

.card img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.card-body {
    padding: 15px;
}

.card-body h5 {
    font-size: 1.25rem;
    margin: 10px 0 5px;
    color: #333;
}

.card-body p {
    font-size: 1rem;
    color: #555;
}
</style>
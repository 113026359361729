import axios from 'axios';
import store from '@/store'; // Import your Vuex store if needed for authentication
import { apiConfig } from '@/utils/config';

// Create an Axios instance
const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL || apiConfig.baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Interceptor to handle retries
apiClient.interceptors.response.use(
  response => response, // Return the response if successful
  async error => {
    const config = error.config;
    if (!config || !config.retry) return Promise.reject(error);

    config.__retryCount = config.__retryCount || 0;

    if (config.__retryCount >= config.retry) {
      // Reject with the error after exhausting retries
      return Promise.reject(error);
    }

    config.__retryCount += 1;

    // Optional: Add delay between retries
    const delay = new Promise(resolve => setTimeout(resolve, config.retryDelay || 1000));

    await delay;

    // Retry the request
    return apiClient(config);
  }
);

// Centralized methods for HTTP requests
const apiService = {
  get(endpoint, params = {}, requiresAuth = true, retry = 3, retryDelay = 1000) {
    const config = {
      params,
      retry,
      retryDelay,
      headers: {},
    };

    if (requiresAuth) {
      const token = store.getters['auth/token'];
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return apiClient.get(endpoint, config);
  },

  post(endpoint, data, requiresAuth = true, config = {}) {
    config.retry = config.retry || 3;
    config.retryDelay = config.retryDelay || 1000;

    if (requiresAuth) {
      const token = store.getters['auth/token'];
      if (token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        };
      }
    }

    return apiClient.post(endpoint, data, config);
  },

  put(endpoint, data, requiresAuth = true, config = {}) {
    config.retry = config.retry || 3;
    config.retryDelay = config.retryDelay || 1000;

    if (requiresAuth) {
      const token = store.getters['auth/token'];
      if (token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
          "Cookie": document.cookie
        };
      }
    }

    return apiClient.put(endpoint, data, config);
  },

  delete(endpoint, requiresAuth = true, config = {}) {
    config.retry = config.retry || 3;
    config.retryDelay = config.retryDelay || 1000;

    if (requiresAuth) {
      const token = store.getters['auth/token'];
      if (token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
          "Cookie": document.cookie
        };
      }
    }

    return apiClient.delete(endpoint, config);
  },
};

export default apiService;

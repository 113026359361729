import VueJwtDecode from 'vue-jwt-decode';

export function isTokenExpired(token) {
    if (!token) return true;
    const tokenParts = token.split('.');
    if (tokenParts.length !== 3) {
        return true;
    }

    try {
        const payload = JSON.parse(atob(tokenParts[1])); // Decode base64 payload
        const expiresIn = payload.exp; // Extract 'exp' field (in Unix seconds)
       
        if (!expiresIn || typeof expiresIn !== 'number') return true;
        const decoded = VueJwtDecode.decode(token);
        const currentTime = Math.floor(Date.now() / 1000);
        const isExpired = currentTime >= decoded.exp;
        return isExpired;
    } catch (error) {
        console.error('Invalid token format:', error);
        return true;
    }
}

export function isAuthenticated() {
    const token = localStorage.getItem('token'); // Check if a token exists in localStorage
    return !!token; // Return true if token exists, false otherwise
}

export function getUserObject() {
    const token = localStorage.getItem('token'); // Check if a token exists in localStorage
    if (!token) {
        return null; // Return null if no token exists
    }

    try {
      
        const decodedToken = VueJwtDecode.decode(token); // Decode the token using the decode method
        console.log(JSON.stringify(decodedToken));
        return decodedToken; // Return the decoded user object
    } catch (error) {
        console.error('Failed to decode token:', error);
        return null; // Return null if decoding fails
    }
}

export function getUserRoles() {
  const token = localStorage.getItem('token');
  const tokenPayload = token ? VueJwtDecode.decode(token) : null; // Decode JWT payload using vue-jwt-decode

  // Check if tokenPayload exists and has the role property
  return tokenPayload && tokenPayload.role ? tokenPayload.role : []; // Retrieve roles from token payload
}

export function hasRoles(requiredRoles) {
    return requiredRoles.some((role) => getUserRoles().includes(role));
}

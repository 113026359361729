import axios from 'axios';
import { isTokenExpired } from '@/utils/auth';
import { apiConfig } from '@/utils/config';
import signalRService from '@/services/signalRService';
import VueJwtDecode from 'vue-jwt-decode';

let timer = null; // Timer variable to store the token expiration timer

const state = {
    token: localStorage.getItem('token') || null, // Store the token directly
    showHeader: true,
};

const mutations = {
    SET_TOKEN(state, token) {
        state.token = token;
    },
};

const actions = {
    async login({ commit, dispatch }, credentials) {
        try {
            await dispatch('showSpinner', null, { root: true });

            const response = await axios.post(`${apiConfig.baseUrl}judge/login-by-code`, {code: credentials.password});
            if (response.data.success) {
                const token = response.data.token; // Assume the token is returned

                commit('SET_TOKEN', token);
                localStorage.setItem('token', token); // Store the token in localStorage

                // Decode token to get user info
                const decoded = VueJwtDecode.decode(token);
                console.log(decoded); // Print decoded token for inspection

                // Start token expiration timer
                dispatch('startTokenExpirationTimer', decoded.exp);
                await dispatch('hideSpinner', null, { root: true });
                await signalRService.initializeConnection();
                return decoded; // Return decoded user info
            } else {
                throw { error: { code: '500', message: response.data.message } }
            }
        } catch (error) {
            throw error.response ? error.response.data : error;
        } finally {
            await dispatch('hideSpinner', null, { root: true });
        }
    },
    async logout({ commit, dispatch }) {
        dispatch('showAdminHeader', false, { root: true });
        commit('SET_TOKEN', null);
        localStorage.removeItem('token'); // Remove token from localStorage

        if (signalRService.connection) {
            try {
                await signalRService.connection.stop();
                console.log('SignalR disconnected');
            } catch (err) {
                console.error('SignalR disconnection error:', err);
            }
        }
        clearInterval(timer);
        timer = null;
    },
    async initializeStore({ commit, dispatch }) {
        const token = localStorage.getItem('token');


        if (token) {
            const isExpired = isTokenExpired(token);
            if (isExpired) {
                // Token has expired, attempt to refresh token
                try {
                    const refreshedToken = await dispatch('refreshToken');

                    if (refreshedToken) { // Check if refresh was successful
                        commit('SET_TOKEN', refreshedToken);
                        dispatch('startTokenExpirationTimer', VueJwtDecode.decode(refreshedToken).exp);
                    } else {
                        // Refresh failed, clear token state
                        commit('SET_TOKEN', null);
                        localStorage.removeItem('token');
                    }
                } catch (error) {
                    // Handle any errors during the token refresh process
                    console.error('Failed to refresh token:', error);
                    commit('SET_TOKEN', null);
                    localStorage.removeItem('token');
                }
            } 
            else{
                commit('SET_TOKEN', token);
            }
            signalRService.initializeConnection();
        }
      
    },
    
    startTokenExpirationTimer({ dispatch }, expirationTime) {
        // Clear existing timer if it exists
        if (timer) {
            clearInterval(timer);
            timer = null;
        }

        // Calculate token expiration time
        const now = Date.now();
        const timeout = expirationTime * 1000 - now; // Convert expiration to milliseconds

        // Set timer to refresh token 5 minutes before expiration
        const refreshTime = Math.max(5 * 60 * 1000, timeout - (5 * 60 * 1000)); // 5 minutes in milliseconds
        timer = setTimeout(async () => {
            console.log('Token refresh initiated...');
            try {
                const refreshedToken = await dispatch('refreshToken');
                dispatch('startTokenExpirationTimer', VueJwtDecode.decode(refreshedToken).exp);
                console.log('Token refreshed successfully!');
            } catch (error) {
                console.error('Failed to refresh token:', error);
                dispatch('logout');
            }
        }, refreshTime);
    },
    async refreshToken({ commit, state }) {
        // Check if the token is valid
        if (!state.token) {
            console.warn("No valid token available.");
            return null; // Return null or handle as needed
        }
    
        try {
            // Make a GET request to refresh the token
            const response = await axios.get(
                `${apiConfig.baseUrl}u/refreshtoken`,
                {
                    headers: {
                        Authorization: `Bearer ${state.token}` // Include token as bearer token
                    }
                }
            );

            // If successful, update token state
            const refreshedToken = response.data.token; // Assume the refreshed token is returned
            commit('SET_TOKEN', refreshedToken);
            localStorage.setItem('token', refreshedToken); // Store the refreshed token

            return refreshedToken; // Return refreshed token if needed
        } catch (error) {
            console.error("Failed to refresh token:", error);

            // Handle unauthorized error
            if (error.response && error.response.status === 401) {
                console.warn("User session might be expired. Consider logging out.");
                commit('SET_TOKEN', null); // Clear token state
                localStorage.removeItem('token'); // Optionally clear local storage
                // Optionally, dispatch logout action or notify user
            } else {
                console.warn("Network or server error occurred while refreshing token.");
            }

            // Return a fallback value or null
            return null;
        }
    },
};

const getters = {
    isAuthenticated: state => !!state.token && !isTokenExpired(state.token),
    currentUser: state => state.token ? VueJwtDecode.decode(state.token) : null, // Decode token for user info
    userRoles: state => state.token ? VueJwtDecode.decode(state.token).roles : [],
    showHeader: state => state.showHeader,
    token: state => state.token
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

<template>
    <div v-if="!isShowSlider" class="card shadow-lg mx-auto my-4"
        style="max-width: 100%; font-family: 'Poppins', sans-serif;">
        <div class="card-body">

            <div class="table-responsive">
                <table class="table table-hover align-middle text-center mt-3">
                    <thead class="table-success">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col" colspan="2">Contestant Name</th>
                            <th scope="col">Contestant Agency</th>
                            <th scope="col" v-for="(criteria) in (scoreCards[0]?.judgeScores[0]?.scores || [])"
                                :key="criteria.criteriaId">
                                <div class="d-flex flex-column align-items-center">
                                    <span class="fw-bold">{{ criteria.criterionName }}</span>
                                    <small class="text-muted">{{ criteria.weight }}%</small>
                                </div>
                            </th>
                            <th scope="col">Total Score</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="scoreCard in scoreCards" :key="scoreCard.contestantId">
                            <td class="fw-bold text-danger">{{ scoreCard.userPK }}</td>
                            <td class="text-middle" style="min-width: 60px;"><img :src="`${apiConfig.baseUrl}${scoreCard.profilePic}`"
                                    alt="Profile Picture" class="rounded-circle img-fluid"
                                    style="width: 50px; height: 50px; object-fit: cover;" /></td>
                            <td class="text-start text-warning fw-bold">{{ scoreCard.contestantName }}</td>
                            <td class="text-middle">{{ scoreCard.contestantAgency }}</td>
                            <td v-for="(score) in (scoreCard?.judgeScores[0]?.scores || [])" :key="score.scoreId">
                                <input class="form-control text-center fw-bold mx-auto gradient-border" type="text"
                                    style="width: 80px;" max="10" step="0.1" @keypress="validateNumericInput"
                                    @input="validateScore(score)" v-model.number="score.scoreValue"
                                    @blur="formatScore(score)" />
                            </td>
                            <td class="fw-bold">{{ scoreCard.totalScore }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="text-end">
                <button @click="saveScores" class="btn btn-danger mb-3"
                    type="button"><i class="bi bi-save"></i> Save</button>
            </div>

            <div class="text-center">
                <p class="text-secondary fs-5">
                    Powered By: <b>Exodous I.T. Solutions</b>
                    <br>
                    <img src="/favicon.ico" alt="Logo" class="d-inline-block align-text-top" width="30" height="30" />
                    Tabulation System
                </p>
            </div>
        </div>
    </div>
    <div v-else>
        <CandidateSlider class="mt-5" />
    </div>
</template>

<style scoped>
* {
    font-family: 'Poppins', sans-serif;

}

.gradient-border {
    border: 2px solid transparent;
    border-radius: 8px;
    background: linear-gradient(to right, rgb(242, 112, 156), rgb(255, 148, 114));
    background-clip: padding-box;
}
</style>


<script setup>
import dashboardService from "@/services/dashboardService";
import spinnerService from "@/services/spinnerService";
import { onMounted, ref } from "vue"
import signalRService from "@/services/signalRService";
import toastService from "@/services/toastService";
import CandidateSlider from "@/components/CandidateSlider.vue";
import { apiConfig } from "@/utils/config";
import { useStore } from "vuex";

const store = useStore();
const isShowSlider = ref(false);
const currentContest = ref({ name: '' });
const scoreCards = ref([{ judgeScores: [] }]);
const fetchData = async () => {
    spinnerService.show();
    const resp = await dashboardService.getCurrentScores();
    scoreCards.value = resp.scoreCards;
    currentContest.value = resp.contest;
    store.dispatch("setCurrentEventTitle",currentContest.value.name);
    if (currentContest.value.id === "EEA02F93-42E5-445D-9F48-0112E0E913F1" || currentContest.value.name === "Show Slider") {
        isShowSlider.value = true;
    }
    else {
        isShowSlider.value = false;
    }
    spinnerService.hide();
}
const validateScore = (score) => {
    if (score.scoreValue < 0 || score.scoreValue > 10) {
        toastService.error("Score must be between 0 and 10.");
        score.scoreValue = Math.min(Math.max(score.scoreValue, 0), 10); // Clamp the value
    }
};
const formatScore = (score) => {
    const value = score.scoreValue?.toString().trim();

    if (!value || isNaN(value)) {
        score.scoreValue = "0";
    } else {
        score.scoreValue = parseFloat(value); // Two decimal precision
    }
};

const validateNumericInput = (event) => {
    const char = event.key;
    const allowedChars = "0123456789.";
    const currentValue = event.target.value;

    // Prevent multiple decimal points
    if (char === "." && currentValue.includes(".")) {
        event.preventDefault();
    }

    // Allow only numeric characters and a single decimal point
    if (!allowedChars.includes(char)) {
        event.preventDefault();
    }
};
const handleStatus = (evnt) => {
    if (currentContest.value.id === evnt.id) {
        currentContest.value.isOpen = evnt.status;
    }
}
const saveScores = async () => {
    spinnerService.show();
    const response = await dashboardService.saveScores(scoreCards.value);
    if (response.success) {
        scoreCards.value = response.scoreCards;
        toastService.success("Scores has been saved successfully!");
    }
    else {
        toastService.error(response.message);
    }
    spinnerService.hide();
}
onMounted(() => {
    signalRService.subscribeToEvent("OnCurrentContestChanged", fetchData);
    signalRService.subscribeToEvent("OnContestStatusChanged", (evnt) => handleStatus(evnt));
    fetchData();
});

</script>
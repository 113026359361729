<template>
    <div class="d-flex align-items-center justify-content-center min-vh-100">
      <form
        class="p-4 bg-light rounded shadow-lg"
        style="min-width: 370px; max-width: 100%"
        @submit.prevent="handleSubmit"
      >
        <div class="text-center mb-1" style="margin-top: -105px">
          <router-link to="/">
            <img src="/2024.png" class="rounded-circle" height="170" width="170" />
          </router-link>
        </div>
        <h1 class="custom-heading text-center mb-4">TABULATION</h1>
  
        <div class="mb-3 form-group">
          <label for="password" class="form-label">Enter Code:</label>
          <div class="input-group">
            <input
              :type="passwordFieldType"
              id="password"
              v-model="password"
              class="form-control"
              required
            />
            <!-- Toggle password visibility icon -->
            <span
              :class="
                passwordFieldType === 'password' ? 'bi bi-eye-slash-fill' : 'bi bi-eye-fill'
              "
              @click="togglePasswordVisibility"
              class="input-group-text"
              style="cursor: pointer"
            ></span>
          </div>
        </div>
  
        <div class="col-12 mb-2 px-0">
          <div v-if="errmsg" class="alert alert-danger mx-0">{{ errmsg }}</div>
        </div>
  
        <!-- Submit button -->
        <button
            type="submit"
            class="btn w-100 mb-2"
            style="background: linear-gradient(98.3deg, rgb(0, 0, 0) 10.6%, rgb(255, 0, 0) 97.7%); border: none; color: white;"
          >
            Login
        </button>
         <!-- Powered by message -->
      <div class="text-center mt-4">
        <p class="text-secondary fs-5">
            <a target="_blank" href="https://exodoustechsolutions.com" class="powered-by-text">Powered by: <strong>Exodous I.T. Solutions</strong></a>
            <br>
            <img src="/favicon.ico" alt="Logo" class="d-inline-block align-text-top" width="30" height="30" />
            
            <a class="powered-by-text"> Tabulation System</a>

        </p>


      </div>
      </form>
  
     
    </div>
  </template>

  <script>
  import { ref } from "vue";
  import { useStore } from "vuex";
  import router from "@/router";
  export default {
    name: "LoginPage",
    setup() {
      const username = ref("");
      const password = ref("");
      const passwordFieldType = ref("password"); // Track whether password is visible or hidden
      const store = useStore();
      const errmsg = ref("");
      const returnURL = router.currentRoute.value.query.return_url || "/";
      const isSubmitting = ref(false);
      const togglePasswordVisibility = () => {
        passwordFieldType.value =
          passwordFieldType.value === "password" ? "text" : "password";
      };
     
      const handleSubmit = async () => {
        try {
          errmsg.value = "";
          await store.dispatch("auth/login", {
            password: password.value,
          });
          router.replace(returnURL);
        } catch (error) {
          console.error("Login error:", error);
          errmsg.value = error.error ? error.error.message : "Server is unreachable.";
        } finally {
          isSubmitting.value = false;
        }
      };
  
      return {
        username,
        password,
        passwordFieldType,
        togglePasswordVisibility,
        handleSubmit,
        errmsg,
        isSubmitting,
      };
    },
  };
  </script>
  
  <style scoped>
  /* Ensure the form has a minimum and maximum width for responsiveness */
      @media (max-width: 576px) {
        form {
          width: 100%;
          margin-left: 10px;
          margin-right: 10px;
        }
      }
      .custom-heading {
          font-family: 'Poppins', sans-serif;
          font-size: 2.5rem; /* Adjust size */
          font-weight: 900; /* Bold */
          background: linear-gradient(108.5deg, rgb(30, 59, 94) 4%, rgb(253, 29, 62) 49.3%, rgb(255, 224, 94) 96%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-shadow: 3px 4px 1px rgba(0, 0, 0, 0.2); /* Optional: add a shadow for depth */
      }

    .powered-by-text {
      font-size: 0.9rem; /* Adjust font size */
      color: #6c757d; /* Bootstrap secondary text color */
      margin-top: 20px; /* Space above */
      /* Optional: add more styling if needed */
      text-decoration: none;
    }
  </style>
  
<template>
  <HeaderNavigation />
</template>

<script>
import HeaderNavigation from "./components/HeaderNavigation.vue";

export default {
  name: "App",
  components: {
    HeaderNavigation,
  },
};
</script>

<style>
  body {
    background-color: black;
    color: rgb(0, 0, 0); /* Optional: to make text visible on a black background */
  }
  body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.7) 1px, transparent 1px);
  background-size: 20px 20px; /* Adjust for star size */
  opacity: 0.2; /* Adjust for star brightness */
  pointer-events: none; /* Allow clicks through to the content */
}
body::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.7) 1px, transparent 1px);
  background-size: 20px 20px; /* Adjust for star size */
  opacity: 0.2; /* Adjust for star brightness */
  pointer-events: none; /* Allow clicks through to the content */
}
</style>

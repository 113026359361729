<template>
  <SpinnerOverlay />
  <ConfirmDialog />
  <div>
    <nav v-if="showAdminHeader" class="navbar navbar-expand-lg fixed-top"
      style="background-image: url('/header.png'); background-size: cover; background-position: center;">
      <div class="container-fluid">
        <!-- Left section -->
        <div class="d-flex align-items-center">
          <button v-if="hasRequiredRole(['admin'])" @click="toggleSidebar"
            class="btn btn-sm btn-outline-light me-2 mt-1" type="button">
            <i class="bi bi-list text-dark fs-3"></i>
          </button>
          <router-link to="/" class="navbar-brand text-decoration-none">
            <img src="/2024.png" alt="Logo" class="d-inline-block align-text-top" width="50" height="50" />
          </router-link>
        </div>

        <!-- Center section -->
        <div class="text-center flex-grow-1">
          <h1
            class="fw-bold text-uppercase mt-2 d-flex flex-column flex-md-row align-items-center justify-content-center header-text">
            <span class="d-block fw-bold mx-1">
              {{ currentEventTitle !== 'Show Slider' ? currentEventTitle : 'MUTYA NG PALOMPON 2024' }}
            </span>
            <span class="bi bi-magic text-warning d-none"></span>
          </h1>
        </div>

        <!-- Right section -->
        <div class="d-flex align-items-center ms-auto">
          <div class="dropdown">
            <a title="Account" class="username d-flex align-items-center me-2 navbar-brand" href="#" id="userDropdown"
              data-bs-toggle="dropdown" aria-expanded="false">
              <i class="bi bi-person-circle me-1 fs-2"></i>
              <span class="d-none fw-bold text-white d-lg-block">
                {{ currentUser ? currentUser.unique_name.toUpperCase() : "Guest" }}
              </span>
              
            </a>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
              <li>
                <h3 class="dropdown-header text-center">
                  {{ currentUser ? currentUser.winaccountname : "" }}
                </h3>
              </li>
              <li v-if="currentUser && hasRequiredRole(['admin', 'web-scan-live'])">
                <a href="#" @click.prevent="toggleLiveScan('live')" class="dropdown-item">
                  <i class="bi bi-qr-code-scan me-1"></i> Live Scan
                </a>
              </li>
              <li v-if="currentUser && hasRequiredRole(['admin', 'web-scan-print'])">
                <a href="#" @click.prevent="toggleLiveScan('print')" class="dropdown-item">
                  <i class="bi bi-qr-code-scan me-1"></i> ID Print Scan
                </a>
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>
              <li>
                <a href="#" @click.prevent="logout" class="dropdown-item">
                  <i class="bi bi-box-arrow-left me-1"></i> Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>


    <div class="d-flex b-danger" id="content-container">
      <div v-if="showAdminHeader && hasRequiredRole(['admin'])"
        :class="['bg-light', 'border-end', 'sidebar-wrapper', { toggled: isSidebarToggled }]" id="sidebar-wrapper"
        style="z-index: 99;">
        <div class="list-group list-group-flush">
          <router-link to="/" class="list-group-item list-group-item-action bg-light mt-3">
            <i class="bi bi-speedometer me-2"></i> Dashboard
          </router-link>
          <div class="accordion" id="shortcutsAccordion">
            <div class="accordion-item">
              <button class="accordion-button collapsed bg-light text-decoration-none" type="button"
                data-bs-toggle="collapse" data-bs-target="#shortcutsCollapse" aria-expanded="false"
                aria-controls="shortcutsCollapse">
                <i class="bi bi-people me-2"></i> Voters
              </button>
              <div id="shortcutsCollapse" class="accordion-collapse collapse" aria-labelledby="shortcutsCollapse"
                data-bs-parent="#shortcutsAccordion">
                <div class="accordion-body" v-if="currentUser && hasRequiredRole(['admin', 'voters'])">
                  <router-link to="/voters" class="dropdown-item py-2">
                    <i class="bi bi-people-fill me-2"></i> Voters List
                  </router-link>
                  <router-link v-if="currentUser && hasRequiredRole(['admin', 'households'])" to="/households"
                    class="dropdown-item py-2">
                    <i class="bi bi-house-door-fill me-2"></i> Households
                  </router-link>
                  <router-link v-if="currentUser && hasRequiredRole(['admin', 'heatmap'])" to="/heatmap"
                    class="dropdown-item py-2">
                    <i class="bi bi-geo-alt-fill me-2"></i> Heat Map
                  </router-link>
                  <router-link v-if="currentUser && hasRequiredRole(['admin', 'import'])" to="/imports"
                    class="dropdown-item py-2">
                    <i class="bi bi-person-fill-down me-2"></i> Imports
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion" id="settingsAccordion">
            <div class="accordion-item">
              <button class="accordion-button collapsed bg-light text-decoration-none" type="button"
                data-bs-toggle="collapse" data-bs-target="#settingsCollapse" aria-expanded="false"
                aria-controls="settingsCollapse">
                <i class="bi bi-gear me-2"></i> Settings
              </button>
              <div id="settingsCollapse" class="accordion-collapse collapse" aria-labelledby="settingsCollapse"
                data-bs-parent="#settingsAccordion">
                <div class="accordion-body">
                  <router-link to="/city-setup" v-if="currentUser && hasRequiredRole(['admin', 'city-setup'])"
                    class="dropdown-item py-2">
                    <i class="bi bi-buildings-fill me-2"></i> Municipal Settings
                  </router-link>
                  <router-link v-if="currentUser && hasRequiredRole(['admin', 'brgy-setup'])" to="/brgy-setup"
                    class="dropdown-item py-2">
                    <i class="bi bi-house-door-fill me-2"></i> Barangay Settings
                  </router-link>
                  <router-link v-if="currentUser && hasRequiredRole(['admin', 'heatmap-setup'])" to="/map-setup"
                    class="dropdown-item py-2">
                    <i class="bi bi-gear-fill me-2"></i> Map Settings
                  </router-link>
                  <router-link v-if="currentUser && hasRequiredRole(['admin', 'judge'])" to="/user-setup"
                    class="dropdown-item py-2">
                    <i class="bi bi-person-fill-gear me-2"></i> User Settings
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <router-link v-if="currentUser && hasRequiredRole(['admin', 'judge'])" to="/reports"
            class="list-group-item list-group-item-action bg-light mt-3">
            <i class="bi bi-file-earmark-bar-graph me-2"></i> Reports
          </router-link>
        </div>
      </div>

      <main id="main-content" class="flex-grow-1 p-3" style="z-index: 1;">
        <router-view />
      </main>
    </div>
  </div>
</template>


<style scoped>
* {
  font-family: 'Poppins', sans-serif;

}
</style>


<script setup>
import { ref, computed, onBeforeUnmount, onMounted, watch } from "vue"; // Added computed and watch
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import confirmationService from "@/services/confirmationService";
import signalRService from "@/services/signalRService";
import SpinnerOverlay from "./animations/SpinnerOverlay.vue";
import ConfirmDialog from "./animations/ConfirmDialog.vue";
import { getUserRoles, getUserObject } from "@/utils/auth"; // Assuming getUserRoles is imported here

const isSidebarToggled = ref(false);
const store = useStore();
const router = useRouter();
const currentUser = computed(() => getUserObject()); // Use computed for reactivity

const showAdminHeader = ref(store.state.showAdminHeader);
const currentEventTitle = computed(() => store.state.currentEventTitle);
const showLiveScan = ref(false);
const scanMode = ref("live");

const hasRequiredRole = (requiredRoles) => {
  return requiredRoles.some(role => getUserRoles().includes(role));
};


const toggleLiveScan = (mode) => {
  scanMode.value = mode;
  showLiveScan.value = !showLiveScan.value;
};

const toggleSidebar = () => {
  isSidebarToggled.value = !isSidebarToggled.value;
};

const logout = async () => {
  const confirmed = await confirmationService.show(
    "Are you sure you want to logout?"
  );
  if (!confirmed) return;

  await store.dispatch("auth/logout"); // Log out the user
  store.dispatch("showAdminHeader", false); // Hide the admin header
  router.push("/login"); // Redirect to login page
};

watch(
  () => store.state.showAdminHeader,
  (newVal) => {
    showAdminHeader.value = newVal;
  }
);

// Manage header visibility based on route meta field
const updateHeaderVisibility = () => {
  const currentRoute = router.currentRoute.value;
  showAdminHeader.value = currentRoute.meta.showHeader !== false;
};

onMounted(() => {
  updateHeaderVisibility();
});

onBeforeUnmount(() => {
  signalRService.connection
    .stop()
    .then(() => {
      console.log("SignalR connection stopped");
    })
    .catch((err) => {
      console.error("Error stopping SignalR connection:", err);
    });
});
</script>

<style scoped>
#content-container {
  margin-top: 55px;
  height: calc(100vh - 55px);
}

a.username {
  text-decoration: none;
  color: rgb(158, 29, 29);
}

.sidebar-wrapper {
  width: 250px;
  transition: all 0.3s ease;
  height: calc(100vh - 55px);
}

#page-content-wrapper {
  width: 100%;
  padding: 20px;
  transition: all 0.3s ease;
}

.sidebar-wrapper.toggled {
  transform: translateX(-100%);
}

.header-text {
  background: linear-gradient(108.5deg, 
      rgb(59, 1, 1) 0%, /* Dark base */
      rgb(255, 223, 0) 50%, /* Lighter gold highlight */
      rgb(190, 150, 0) 100%); /* Darker gold end */
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 
    1px 1px 2px rgba(107, 5, 5, 0.6), /* Strong dark shadow */
    0 0 14px rgba(255, 223, 0, 1), /* Glowing gold effect */
    0 0 16px rgba(190, 150, 0, 1); /* Subtle gold highlight glow */
}






#page-content-wrapper.toggled {
  margin-left: -250px;
}

@media (max-width: 767px) {
  .sidebar-wrapper {
    width: 250px;
    position: fixed;
    transform: translateX(-100%);
  }

  .sidebar-wrapper.toggled {
    transform: translateX(0);
  }

  #page-content-wrapper.toggled {
    margin-left: 0;
  }

  #page-content-wrapper {
    margin-left: 0;
  }
}
</style>

import apiService from "./apiService";

const dashboardService = {
    async getCurrentScores() {
        try {
            return (await apiService.get(`score/get-recent-scorecards`, {}, true)).data;
        }
        catch {
            return [];
        }
    },
    async saveScores(scores) {
            const resp = (await apiService.post(`score/save-scores`, scores, true)).data;
            return resp;
    },
    async getCandidates() {
        const resp = (await apiService.post(`contestant/search`, {}, true)).data;
        return resp;
},

};

export default dashboardService;